import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/auth'; // Import the login function
import LoginForm from '../components/LoginForm';
import { Container, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logos/logo.png';

interface LoginPageProps {
    onLoginSuccess: (role: string) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLoginSuccess }) => {
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation(); // Use translation hook

    const handleLogin = async (username: string, password: string) => {
        try {
            console.log("df")
            const response = await login(username, password); // Use login function
            const { token, role } = response.data;

            localStorage.setItem('authToken', token);
            localStorage.setItem('userRole', role);

            setError('');
            onLoginSuccess(role);
            navigate('/');
        } catch (err: any) {
            console.error('Login error:', err.response?.data || err.message);
            if (err.response && err.response.status === 400) {
                setError(t('login.invalidCredentials'));
            } else {
                setError(t('login.errorOccurred'));
            }
        }
    };

    return (
        <Container fluid className="min-vh-100 p-0 d-flex">
            {/* Left Section with Logo and Slogan */}
            <Col
                md={6}
                className="d-none d-md-flex align-items-center justify-content-center"
                style={{
                    background: 'linear-gradient(135deg, #1c1c1c, #333)', // Darker gradient
                    color: 'white',
                    flexDirection: 'column',
                    padding: '3rem',
                    textAlign: 'center',
                    position: 'relative',
                }}
            >
                <div>
                    {/* Use translations for app name and slogan */}
                    <img
                        src={logo}
                        alt={t('branding.brand')}
                        style={{
                            maxHeight: '200px',
                            marginBottom: '1.5rem',
                            filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2))',
                        }}
                    />
                    <h1 style={{ fontWeight: '700', fontSize: '2.5rem', marginBottom: '1rem' }}>
                        {t('branding.brand')}
                    </h1>
                    <p style={{ fontSize: '1.25rem', fontWeight: '300' }}>
                        {t('branding.slogan')}
                    </p>
                </div>
            </Col>

            {/* Right Section with Form */}
            <Col
                xs={12}
                md={6}
                className="d-flex align-items-center justify-content-center p-4"
                style={{ background: '#f5f7fa' }} // Light background for contrast
            >
                <Card
                    className="shadow p-4"
                    style={{
                        width: '100%',
                        maxWidth: '400px',
                        borderRadius: '10px',
                        border: 'none',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {/* Render the Login Form */}
                    <LoginForm onSubmit={handleLogin} error={error} />

                    {/* Optional footer slogan for mobile */}
                    <div className="text-center mt-4 d-md-none">
                        <p style={{ fontSize: '1.5rem', fontWeight: '500', color: '#555' }}>
                            {t('branding.slogan')}
                        </p>
                    </div>
                </Card>
            </Col>
        </Container>
    );
};

export default LoginPage;
