import React from 'react';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
    userRole: string | null;
    onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ userRole, onLogout }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    // Language toggle handler
    const toggleLanguage = () => {
        const newLang = i18n.language === 'sk' ? 'en' : 'sk';
        i18n.changeLanguage(newLang);
    };

    return (
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top" className="shadow-sm">
            <Container>
                {/* Brand */}
                <LinkContainer to="/">
                    <Navbar.Brand className="fw-bold">{t('branding.brand')}</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {/* Navigation Links */}
                    <Nav className="me-auto">
                        {/* Admin-only links */}
                        {userRole === 'admin' && (
                            <>
                                <LinkContainer to="/storage">
                                    <Nav.Link>{t('header.warehouse')}</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/products">
                                    <Nav.Link>{t('header.products')}</Nav.Link>
                                </LinkContainer>
                            </>
                        )}
                        {/* Shared link for both admin and optimizer */}
                        {userRole && (
                            <LinkContainer to="/optimize">
                                <Nav.Link>{t('header.optimization')}</Nav.Link>
                            </LinkContainer>
                        )}
                    </Nav>
                    {/* Right-side Buttons */}
                    <Nav className="ml-auto">
                        {/* Language Toggle Button */}
                        <Button
                            variant="outline-light"
                            onClick={toggleLanguage}
                            className="me-2"
                            size="sm"
                        >
                            {i18n.language === 'en' ? 'SK' : 'EN'}
                        </Button>

                        {/* Login/Logout */}
                        {userRole ? (
                            <Button variant="outline-light" onClick={onLogout} size="sm">
                                {t('header.logout')}
                            </Button>
                        ) : (
                            <LinkContainer to="/login">
                                <Button variant="outline-light" size="sm">
                                    {t('login.loginButton')}
                                </Button>
                            </LinkContainer>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
