import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import LoginPage from './pages/LoginPage';
import WarehousePage from './pages/WarehousePage';
import ProductsPage from './pages/ProductsPage';
import OptimizationPage from './pages/OptimizationPage';
import MainPage from './pages/MainPage';
import Header from './components/Header';
import ProtectedRoute from './components/ProtectedRoute';
import { validateSession, logout } from './api/auth'; // Import the functions from api/auth.ts

const App: React.FC = () => {
    const [userRole, setUserRole] = useState<string | null>(null); // Default to null
    const [isLoading, setIsLoading] = useState(true); // Loading state to handle validation
    const navigate = useNavigate();

    // Function to check session validity
    const handleValidateSession = async () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            setUserRole(null);
            setIsLoading(false);
            return; // No need to navigate away from the main page
        }

        try {
            const response = await validateSession(); // Use validateSession from api/auth
            if (!response || !response.data.role) {
                throw new Error('Invalid session');
            }

            const { role } = response.data;
            setUserRole(role);
            setIsLoading(false);

        } catch (error) {
            console.error('Session validation failed:', error);
            localStorage.removeItem('authToken');
            localStorage.removeItem('userRole');
            setUserRole(null);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleValidateSession(); // Validate session on initial load
    }, []);

    const handleLogout = async () => {
        try {
            await logout(); // Call the logout function from api/auth
            localStorage.removeItem('authToken');
            localStorage.removeItem('userRole');
            setUserRole(null);
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const handleLoginSuccess = (role: string) => {
        localStorage.setItem('userRole', role);
        setUserRole(role);
        navigate('/'); // Optionally, navigate to the main page after login
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {/* Always show the header, but pass different props based on login status */}
            <Header userRole={userRole} onLogout={handleLogout} />

            <Container className="mt-4">
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />

                    {/* Protected routes */}
                    {userRole === 'admin' && (
                        <>
                            <Route
                                path="/storage"
                                element={
                                    <ProtectedRoute requiredRole="admin">
                                        <WarehousePage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/products"
                                element={
                                    <ProtectedRoute requiredRole="admin">
                                        <ProductsPage />
                                    </ProtectedRoute>
                                }
                            />
                        </>
                    )}

                    {userRole && (
                        <Route
                            path="/optimize"
                            element={
                                <ProtectedRoute requiredRole={userRole}>
                                    <OptimizationPage />
                                </ProtectedRoute>
                            }
                        />
                    )}

                    {/* Redirect all other routes to main page */}
                    <Route path="*" element={<MainPage />} />
                </Routes>
            </Container>
        </>
    );
};

export default App;
