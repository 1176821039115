import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import en from './locales/en/translation.json';
import sk from './locales/sk/translation.json';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en
        },
        sk: {
            translation: sk
        }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
