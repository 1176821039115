import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
    children: ReactNode;  // This defines the type for children (which will be a React element)
    requiredRole: string; // This defines the type for requiredRole (which is a string)
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRole }) => {
    const token = localStorage.getItem('authToken');
    const userRole = localStorage.getItem('userRole');

    // Check if user is logged in and has the required role
    if (!token || !userRole || userRole !== requiredRole) {
        return <Navigate to="/login" />;
    }

    return <>{children}</>; // Render the children if user has the required role
};

export default ProtectedRoute;
