import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logos/logo.png';

const MainPage: React.FC = () => {
    const navigate = useNavigate();
    const userRole = localStorage.getItem('userRole');
    const { t } = useTranslation();

    return (
        <Container fluid className="vh-100 d-flex flex-column">
            {/* Hero Section */}
            <Row
                className="d-flex align-items-center justify-content-center bg-dark text-white p-5"
                style={{
                    background: 'linear-gradient(135deg, #333, #1c1c1c)', // Dark gradient for contrast
                    minHeight: '40vh',
                    textAlign: 'center',
                }}
            >
                <Col md={8} lg={6}>
                    <img
                        src={logo}
                        alt="OptiFoundry Logo"
                        style={{
                            maxHeight: '150px',
                            marginBottom: '1.5rem',
                            filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2))',
                        }}
                    />
                    <h1 className="display-4">{t('branding.brand')}</h1>
                    <p className="lead">{t('branding.slogan')}</p>
                </Col>
            </Row>

            {/* Content Section */}
            <Row className="flex-grow-1 d-flex align-items-center justify-content-center bg-light text-center p-5">
                <Col md={8} lg={6}>
                    <h2>{t('mainPage.welcome')}</h2>
                    <p className="mb-4">{t('mainPage.description')}</p>
                    <p className="mb-4">{t('mainPage.instructions')}</p>

                    {/* Display buttons based on user role */}
                    {userRole === 'admin' && (
                        <>
                            <Button
                                onClick={() => navigate('/storage')}
                                variant="primary"
                                size="lg"
                                className="m-2"
                            >
                                {t('storagePage.title')}
                            </Button>
                            <Button
                                onClick={() => navigate('/products')}
                                variant="secondary"
                                size="lg"
                                className="m-2"
                            >
                                {t('productsPage.title')}
                            </Button>
                        </>
                    )}

                    {userRole === 'optimizer' && (
                        <Button onClick={() => navigate('/optimize')} variant="primary" size="lg" className="m-2">
                            {t('optimizationPage.title')}
                        </Button>
                    )}

                    {/* If no user is logged in, show login button */}
                    {!userRole && (
                        <Button href="/login" variant="primary" size="lg">
                            {t('login.loginButton')}
                        </Button>
                    )}
                </Col>
            </Row>

            {/* Footer Section */}
            <Row className="bg-dark text-white text-center p-3">
                <Col>
                    <p className="mb-0">
                        © {new Date().getFullYear()} {t('branding.brand')}.
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default MainPage;
